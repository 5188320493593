<template>
  <ClientOnly>
    <Swiper
      v-if="reward.media.gallery.length > 0"
      class="swiper reward-image"
      slides-per-view="auto"
      :navigation="true"
      :auto-height="true"
      v-bind="$attrs"
      @swiper="onSwiper"
    >
      <SwiperSlide
        v-for="(item, index) in reward.media.gallery"
        :key="index"
        class="swiper-slide"
      >
        <img
          v-if="index === 0"
          :src="item.conversions.medium"
          class="gallery-img"
          @load="galleryLoaded"
        />
        <img v-else :src="item.conversions.medium" />
      </SwiperSlide>
    </Swiper>
    <div
      v-else-if="img.url !== null"
      class="reward-image"
      :style="`height:${imgHeight}; width:${imgWidth}`"
    >
      <img ref="rewardimg" :src="img.url" class="reward-img" @load="imgLoaded" />
    </div>
  </ClientOnly>
</template>

<script>
export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      img: {
        url: '',
        height: 224,
        width: 400,
      },
      swiper: null,
    }
  },
  computed: {
    imgHeight() {
      return `${this.img.height}px`
    },
    imgWidth() {
      return `${this.img.width}px`
    },
    scrollContainer() {
      return document !== null
        ? document.querySelector('.layouts-default-container')
        : null
    },
    mySwiper() {
      return this.swiper
    },
  },
  watch: {
    reward(val) {
      this.getImgUrl()
    },
  },
  mounted() {
    this.getImgUrl()
  },
  methods: {
    getImgUrl() {
      if (this.reward.media) {
        if (this.reward.media.gallery.length > 0) {
          this.img.url = this.reward.media.gallery[0].url
          return
        }
        if (this.reward.media.title.length > 0) {
          this.img.url = this.reward.media.title[0].url
          return
        }
      }
      this.img.url = ''
    },
    imgLoaded() {
      this.updateScrollContainer(this.$refs.rewardimg)
    },
    galleryLoaded() {
      this.updateScrollContainer(this.mySwiper)
    },
    updateScrollContainer(imgRef) {
      if (imgRef && imgRef.clientHeight > 0) {
        this.img.height = imgRef.clientHeight
      }

      if (this.scrollContainer && this.scrollContainer.clientWidth > 0) {
        this.img.width = this.scrollContainer.clientWidth
      }
      const scrollHeight = this.img.height - 270
      if (scrollHeight > 0) {
        setTimeout(() => {
          if (this.scrollContainer !== null) {
            this.scrollContainer.scrollTop = scrollHeight
          }

          this.$emit('loaded', this.img.height)
        }, 100)
      }
      else {
        this.$emit('loaded', this.img.height)
      }
    },
    onSwiper(swiperEl) {
      this.swiper = swiperEl
    },
  },
}
</script>

<style scoped>
.reward-image {
  @apply absolute top-0 ;
}

.reward-img {
  @apply w-full;
}

.swiper :deep(.swiper-button-prev) {
  background-image: url('/img/ArrowLeft.png');
  width: 24px;
  height: 42px;
  background-size: 32px 44px;
  background-position: 50%;
  background-repeat: no-repeat;
  top: 20%;
}
.swiper :deep(.swiper-button-prev:after) {
  display: none;
}
.swiper :deep(.swiper-button-next) {
  background-image: url('/img/ArrowRight.png');
  width: 24px;
  height: 42px;
  background-size: 32px 44px;
  background-position: 50%;
  background-repeat: no-repeat;
  top: 20%;
}
.swiper :deep(.swiper-button-next:after) {
  display: none;
}
</style>
