<template>
  <div class="components-rewards-fund-detailprogress">
    <span class="title">
      {{ $t('components.rewards.fund_current') }}
    </span>

    <div class="svg-wrapper">
      <component :is="asyncIcon" :percent="percent" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

const props = defineProps<{
  value: number,
  svg?: string,
}>()

const percent = computed(() => {
  return (props.value > 10 ? props.value : 10) + '%';
})

const asyncIcon = computed(() => {
  if (props.svg !== '') {
    return defineAsyncComponent(
      () => import(`./svg/DetailProgressSVG${props.svg}.vue`),
    )
  }

  return defineAsyncComponent(() => import('./svg/DetailProgressSVG.vue'))
})
</script>

<style lang="scss" scoped>
.title {
  @apply font-theme-title text-theme-primary font-bold text-lg;
}

.svg-wrapper {
  @apply mt-8 flex justify-center;
}
</style>
